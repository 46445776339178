import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import {InputComponent} from 'ui/Input'
import Hotkey from 'ui/Hotkeys'

export default class Button extends InputComponent {
    static propTypes = {
        className: PropTypes.string,
        href: PropTypes.string,
        Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
        shortcut: PropTypes.string,
        hidden: PropTypes.bool,
        flat: PropTypes.bool,
        active: PropTypes.bool,
        small: PropTypes.bool,
        primary: PropTypes.bool,
        borderless: PropTypes.bool,
        disabled: PropTypes.bool,
        toggler: PropTypes.bool,
        element: PropTypes.string,
        type: PropTypes.string,
        onClick: PropTypes.func,
        label: PropTypes.string,
        download: PropTypes.bool,
        onMouseDown: PropTypes.func,
        onMouseUp: PropTypes.func,
    }

    static defaultProps = {
        element: 'button',
        type: 'button',
    }

    getClassName() {
        const {hidden, className, flat, active, small, primary, toggler, borderless, disabled} = this.props

        return classNames('btn', className, {
            'hidden': hidden,
            'btn--flat': flat,
            'btn--primary': primary,
            'btn--small': small,
            'btn--active': active,
            'btn--borderless': borderless,
            'btn--disabled': disabled,
            'btn--toggler': toggler,
        })
    }

    handleClick = e => {
        const {disabled, onClick} = this.props

        if (disabled) {
            e.preventDefault()
        }

        !disabled && onClick && onClick(e)
    }

    render() {
        let {
            Icon,
            children,
            disabled,
            type,
            href,
            label,
            element,
            shortcut,
            download,
            onMouseDown,
            onMouseUp,
        } = this.props

        const inner = label ? label : children

        const content = (
            <span className="btn-content">
                {Icon && <Icon className={'btn-icon ' + (inner ? 'btn-icon--offset' : '')}/>}
                {inner}
                {shortcut && <Hotkey shortcut={shortcut} action={this.handleClick}/>}
            </span>
        )

        const buttonProps = {
            type,
            disabled,
            onClick: this.handleClick,
            className: this.getClassName(),
            onMouseDown,
            onMouseUp,
        }

        buttonProps.children = content

        if (href) {
            element = 'a'
            buttonProps.target = '_blank'
            buttonProps.href = href
            buttonProps.download = download
        }

        return React.createElement(element, buttonProps)
    }
}