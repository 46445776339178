import React, {PureComponent} from 'react'

import CheckIcon from 'icons/check.svg'
import OnlineIcon from 'icons/online.svg'
import AlertIcon from 'icons/alert.svg'
import AlarmIcon from 'icons/alarm.svg'
import OfflineIcon from 'icons/offline.svg'
import InfoIcon from 'icons/info.svg'

import Button from 'ui/Button'

export class ActionCell extends PureComponent {
    onClick = (e) => {
        e.stopPropagation()
        this.props.onClick && this.props.onClick()
    }

    render() {
        const {isChecked, disabled, Icon} = this.props

        if (isChecked) {
            return <center><CheckIcon/></center>
        }

        return (
            <Button primary small disabled={disabled} onClick={this.onClick}>
                {Icon && <Icon/>}
            </Button>
        )
    }
}

const getSeverityIcon = severity => {
    switch (severity) {
        case 'online':
            return OnlineIcon
        case 'offline':
            return OfflineIcon
        case 'alert':
            return AlertIcon
        case 'alarm':
            return AlarmIcon
        default:
            return InfoIcon
    }
}

const getSeverityClass = severity => {
    switch (severity) {
        case 'alarm':
        case 'offline':
            return 'danger'
        case 'alert':
            return 'warning'
        default:
            return 'info'
    }
}

export const SeverityCell = ({severity, description}) => {
    const Icon = getSeverityIcon(severity)

    return (
        <span className={'severity severity--' + getSeverityClass(severity)}>
            <Icon className="severity-icon"/>
            {description}
        </span>
    )
}

export const EventAppointmentCell = ({zone, appointment, partitions}) => {
    if (!zone) {
        return <span>{appointment}</span>
    }

    return (
        <div>
            #{zone} {appointment}
            {partitions && <span className="partitions">
                         {partitions.map(partition => (
                             <span key={partition} className="partition">{partition}</span>
                         ))}
                    </span>}
        </div>
    )
}