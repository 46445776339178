import React from 'react'
import PropTypes from 'prop-types'

import classes from 'classnames'

const Spinner = ({small, className, message}) => (
    <div className={classes('spinner-container', {'spinner-container--small': small}, className)}>
        <div className="spinner">
            <svg className="spinner-element" viewBox="0 0 28 28">
                <circle className="spinner-path" cx="14" cy="14" r="11" fill="none"/>
            </svg>
        </div>
        {message && <p className="spinner-message">{message}</p>}
    </div>
)

Spinner.propTypes = {
    small: PropTypes.bool,
    message: PropTypes.node,
    className: PropTypes.any,
}

export default Spinner