import React from 'react'
import {connect} from 'react-redux'

import getHocName from 'utils/getHocName'
import Error from 'ui/Error'

import {__} from 'utils/i18n'

import {createPermissionsSelector, permissionSelector} from 'modules/auth/permissions/selectors'

export function withPermissionProp(propName = 'isPermitted') {
    return (WrappedComponent) => {
        const Connected = connect(
            (state, {permission}) => ({
                [propName]: permissionSelector(state, permission),
            }),
            () => ({}),
        )(WrappedComponent)

        Connected.displayName = getHocName('withPermissionProp', WrappedComponent)
        return Connected
    }
}

export function withPermission(permissions, propName = 'isPermitted') {
    if (typeof permissions === 'function') {
        permissions = {
            [propName]: permissions,
        }
    }

    return connect(
        createPermissionsSelector(permissions),
        () => ({})
    )
}

export function withRejection() {
    return WrappedComponent => {
        const fn = ({isAllowed, ...props}) => {
            if (isAllowed) {
                return <WrappedComponent {...props}/>
            }

            return (
                <Error title={__('Content not granted')}
                       message={__('Sorry. You are not allowed to see this content.')}/>
            )
        }

        fn.displayName = getHocName('withRejection', WrappedComponent)

        return fn
    }
}