import React, {Component, PureComponent} from 'react'
import validator from 'validate.js'
import PropTypes from 'prop-types'
import serialize from 'form-serialize'

import classes from 'classnames'
import Spinner from 'ui/Spinner'
import getHocName from 'utils/getHocName'

class FormContext {
    listeners = []

    constructor(changeListener) {
        this.changeListener = changeListener
    }

    subscribe(listener: () => any) {
        this.listeners.push(listener)
    }

    notify(data) {
        for (let listener of this.listeners) {
            listener(data)
        }
    }

    changed() {
        this.changeListener()
    }
}

export function withNamedError(WrappedClass) {
    return class extends PureComponent {
        static contextTypes = {
            form: PropTypes.instanceOf(FormContext),
        }

        static displayName = getHocName('NamedError', WrappedClass)

        state = {}

        update = ({errors = {}}) => {
            const {name} = this.props
            if (name) {
                this.setState({error: errors[name]})
            }
        }

        constructor(props, context) {
            super(props, context)
            if (context.form) {
                context.form.subscribe(this.update)
            }
        }

        render() {
            const {error, ...props} = this.props
            return <WrappedClass {...props} error={error || this.state.error}/>
        }
    }
}

const sanitize = data => {
    if (Array.isArray(data)) {
        return data.filter(el => !validator.isEmpty(el))
    }

    if (data instanceof Object) {
        Object.keys(data).forEach(key => {
            data[key] = sanitize(data[key])
        })
    }

    return validator.isEmpty(data) ? null : data
}

class FormRowComponent extends Component {

    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.node,
        error: PropTypes.node,
    }

    render() {
        const {children, error, label, ...props} = this.props

        return (
            <div className={classes('form-field', {'form-field--error': error})} {...props}>
                {label && <h3 className="form-field-label">{label}</h3>}

                <div className="form-fields">
                    {React.Children.toArray(children).map((element, index) =>
                        <div className="form-fields-item" key={index}>
                            {element}
                        </div>,
                    )}
                </div>

                {error && <span className="form-field-error">{error}</span>}
            </div>
        )
    }
}

export const FormRow = withNamedError(FormRowComponent)

class FormValidationGroupComponent extends Component {

    static propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        error: PropTypes.node,
    }

    render() {
        const {children, className, error} = this.props

        return (
            <div className={classes('form-field', className, {'form-field--error': error})}>
                {children}
                {error && <span className="form-field-error">{error}</span>}
            </div>
        )
    }
}

export const FormValidationGroup = withNamedError(FormValidationGroupComponent)

export const FormSection = ({className, title, description, children}) => (
    <div className={classes('form-section', className)}>
        {title && <h3 className={classes('form-section-header', {
            'form-section-header--withDescription': description,
        })}>
            {title}
        </h3>}
        {description && <span className="form-section-description">{description}</span>}
        {children}
    </div>
)

export default class Form extends Component {

    static propTypes = {
        className: PropTypes.string,
        isLoading: PropTypes.bool,
        rules: PropTypes.object,
        validate: PropTypes.func,
        onSubmit: PropTypes.func,
        errors: PropTypes.object,
    }

    static childContextTypes = {
        form: PropTypes.instanceOf(FormContext),
    }

    state = {isSubmitted: false}

    ctx = new FormContext(this.onChange)

    constructor(props, context) {
        super(props, context)

        if (props.errors) {
            this.state = {
                isSubmitted: true,
                errors: props.errors,
            }
        }
    }

    componentWillReceiveProps({errors}, context) {
        if (this.props.errors !== errors) {
            this.setState({isSubmitted: true})
            this.ctx.notify({errors})
        }
    }

    getChildContext() {
        return {form: this.ctx}
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.submit()
    }

    submit() {
        const data = this.getData()
        const errors = this.validate(data)

        this.setErrors(errors)
        this.setState({isSubmitted: true})

        if (errors) {
            return
        }

        const {onSubmit} = this.props
        onSubmit && onSubmit(data)
    }

    getData() {
        return sanitize(serialize(this.refs.element, {hash: true, empty: true}))
    }

    validate(data = this.getData()) {
        const {rules, validate} = this.props
        const result = rules ? validator(data, rules, {fullMessages: false}) : undefined

        if (!validate) {
            return result
        }

        return validate(data, result)
    }

    setErrors(errors = {}) {
        this.ctx.notify({errors})
    }

    onChange = (e) => {
        this.forceUpdate(() => {
            if (this.state.isSubmitted) {
                this.setErrors(this.validate())
            }

            this.props.onChange && this.props.onChange(e)
        })
    }

    render() {
        const {className, isLoading, children} = this.props

        return (
            <form
                ref="element"
                className={classes('form', className, {
                    'form--loading': isLoading,
                })}
                onSubmit={this.onSubmit}
                onChange={this.onChange}
                onInput={this.onChange}>

                {isLoading && <Spinner className="form-spinner"/>}

                <div className="form-content">
                    {children}
                </div>
            </form>
        )
    }

}