import React, {Component} from 'react'

import getHocName from 'utils/getHocName'
import Spinner from 'ui/Spinner'
import Error from 'ui/Error'
import Button from 'ui/Button'
import {__} from 'utils/i18n'

export default function withLoader(onMount: ? (Object) => any, onUnmount: ? (Object) => any) {
    return WrappedComponent => class extends Component {
        static displayName = getHocName('WithLoader', WrappedComponent)

        componentDidMount() {
            onMount && onMount(this.props)
        }

        componentWillUnmount() {
            onUnmount && onUnmount(this.props)
        }

        handleClick = () => {
            onMount && onMount(this.props)
        }

        render() {
            const {isLoading, error, ...props} = this.props

            if (isLoading) {
                return <Spinner/>
            }

            if (error) {
                return (
                    <Error error={error}>
                        {onMount && <Button onClick={this.handleClick}>{__('Retry')}</Button>}
                    </Error>
                )
            }

            return <WrappedComponent {...props}/>
        }
    }
}