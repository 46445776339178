import React, {Component, PureComponent} from 'react'
import PropTypes from 'prop-types'

import classes from 'classnames'
import {withNamedError} from 'ui/Form'
import prepareInputName from 'utils/prepareInputName'

export class InputComponent extends PureComponent {

    static propTypes = {
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onIconClick: PropTypes.func,
        Icon: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.instanceOf(Component),
        ]),
        readOnly: PropTypes.bool,
        name: PropTypes.string,
        error: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        label: PropTypes.string,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        placeholder: PropTypes.string,
        autoFocus: PropTypes.bool,
        secure: PropTypes.bool,
    }

    static defaultProps = {
        type: 'text',
    }

    state = {}

    constructor(props, context) {
        super(props, context)

        this.controlled = props.hasOwnProperty('value')

        if (this.controlled) {
            this.state.value = props.value
        }
    }

    componentWillReceiveProps(props) {
        if (this.props.value !== props.value) {
            this.setState({value: props.value})
        }
    }

    blur() {
        this.element && this.element.blur()
    }

    handleRef = element => this.element = element

    onChange = (e) => {
        if (!this.controlled) {
            this.setState({value: e.target.value, changed: true})
        }

        this.props.onChange && this.props.onChange(e)
    }

    onFocus = (e) => {
        this.setState({focus: true})
        this.props.onFocus && this.props.onFocus(e)
    }

    onBlur = (e) => {
        this.setState({focus: false})
        this.props.onBlur && this.props.onBlur(e)
    }

    renderElement(props) {
        return (
            <input {...props}
                   name={prepareInputName(props.name)}
                   ref={this.handleRef}
                   className="input-field"
                   onChange={this.onChange}
                   onFocus={this.onFocus}
                   onBlur={this.onBlur}
            />
        )
    }

    getValue() {
        if (this.state.changed) {
            return this.props.value || this.state.value
        }

        return this.props.value || this.props.defaultValue
    }

    isDirty() {
        const value = this.getValue()
        return !(value === null || value === undefined || value === '')
    }

    formatError(error) {
        switch (typeof error) {
            case 'string':
                return error
            case 'object':
                return error[Object.keys(error)[0]]
            default:
                return 'New error format ' + JSON.stringify(error)
        }
    }

    render() {
        const {label, className, error, Icon, hasFocus, onIconClick, secure, ...props} = this.props

        const inputClassName = classes('input', {
            'input--label': label,
            'input--dirty': this.isDirty(),
            'input--focus': hasFocus || (!props.readOnly && this.state.focus),
            'input--iconRight': !!Icon,
            'input--secure': secure,
        })

        const icon = Icon && <Icon
            className={classes('input-icon input-icon--right', {'input-icon--action': onIconClick})}
            onClick={onIconClick}
        />

        return (
            <label className={classes('form-field', className, {'form-field--error': error})}>
                <span className={inputClassName}>
                    {label && <span className="form-field-label">{label}</span>}
                    {this.renderElement(props)}
                    {icon}
                </span>

                {error && <span className="form-field-error">{this.formatError(error)}</span>}
            </label>
        )
    }

}

const Input = withNamedError(InputComponent)
export default Input