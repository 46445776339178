import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

const emitter = new class Emitter {
    components = []

    add(item) {
        this.components.unshift(item)
        this.register()
    }

    remove(item) {
        this.components = this.components.filter(component => component !== item)
        this.unregisterIfNeed()
    }

    register() {
        if (!this.registered) {
            document.addEventListener('click', this.clickListener, true)
            this.registered = true
        }
    }

    unregisterIfNeed() {
        if (this.registered && this.components.length === 0) {
            document.removeEventListener('click', this.clickListener, true)
            this.registered = false
        }
    }

    clickListener = e => {
        if (e.target === document.body) {
            // Chrome from version 73 starts send click events from body if is not visible, or hidden by other element
            // so do nothing in this case
            return
        }

        const item = this.components.find(c => c.isActive())

        if (item && item.isOuter(e.target) && item.outerClick(e) !== false) {
            e.stopPropagation()
            e.preventDefault()
        }
    }
}

export default class OuterClick extends PureComponent {

    static propTypes = {
        onOuterClick: PropTypes.func,
        onRef: PropTypes.func,
    }

    componentDidMount() {
        emitter.add(this)
    }

    componentWillUnmount() {
        emitter.remove(this)
    }

    ref = element => {
        this.element = element
        this.props.onRef && this.props.onRef(element)
    }

    isActive() {
        return this.props.children && this.element && this.props.onOuterClick
    }

    isOuter(node) {
        return node !== this.element && !this.element.contains(node)
    }

    outerClick(e) {
        return this.props.onOuterClick && this.props.onOuterClick(e)
    }

    render() {
        const props = Object.assign({}, this.props, {
            ref: this.ref,
        })

        delete props.onOuterClick
        delete props.onRef

        return <div {...props}></div>
    }

}