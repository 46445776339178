import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const VENDOR_NEO = 'NEO'
export const VENDOR_POWER_MASTER = 'POWER_MASTER'
export const VENDOR_FIBRO_TRANSMITTER = 'FIBRO_TRANSMITTER'

export default function panelVendorType(vendor) {
    switch (vendor) {
        case VENDOR_NEO:
            return __('Neo')

        case VENDOR_POWER_MASTER:
            return __('PowerMaster')

        case VENDOR_FIBRO_TRANSMITTER:
            return __('Fibro Transmitter')
    }

    warn(`Unknown vendor ${vendor}`)

    return vendor
}