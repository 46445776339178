import toIds from 'utils/toIds'
import Set from 'es6-set'
import {MAX_SELECTABLE_ROWS} from 'constants/selection'

export default class IdSet {

    set = new Set

    constructor(set = new Set) {
        this.set = set
    }

    add(obj) {
        toIds(obj).forEach(id => !this.isFull() && this.set.add(id))
        return new IdSet(this.set)
    }

    remove(obj) {
        toIds(obj).forEach(id => this.set.delete(id))
        return new IdSet(this.set)
    }

    has(obj) {
        return toIds(obj).every(id => this.set.has(id))
    }

    hasAny(obj) {
        return toIds(obj).some(id => this.set.has(id))
    }

    some(iteratee) {
        for (let item of this.set) {
            if (iteratee(item)) {
                return true
            }
        }

        return false
    }

    isEmpty() {
        return this.count() == 0
    }

    isFull() {
        return this.count() === MAX_SELECTABLE_ROWS
    }

    count() {
        return this.set.size
    }

    toJS() {
        return Array.from(this.set)
    }

    toString() {
        return this.set.toString()
    }

    clear() {
        return new IdSet()
    }
}