import React from 'react'
import PropTypes from 'prop-types'

const Error = ({title, message, error, children}) => {
    message = message || (error && error.message)

    return (
        <div className="error">
            <h1 className="error-title">{title || 'Ooops!'}</h1>
            {message && <div className="error-message">{message}</div>}
            {children}
        </div>
    )
}

Error.propTypes = {
    title: PropTypes.node,
    message: PropTypes.node,
    error: PropTypes.object,
}

export default Error