export const POLL_DEVICES = 3000
export const POLL_DEVICES_BACKGROUND = POLL_DEVICES * 10
export const POLL_WALKTEST = 3000
export const POLL_STATE = 3000
export const POLL_STATE_BACKGROUND = POLL_STATE * 10
export const POLL_PANEL_REMOTE_INSPECTIONS = 3000
export const POLL_PANEL_LOCATIONS = 3000
export const POLL_PANEL_IS_ONLINE = 3000
export const POLL_PANELS = 3000
export const POLL_EVENTS = 3000
export const POLL_EVENT_VIDEO = 2000
export const POLL_VIDEO_ON_DEMAND = 3000
export const POLL_UPGRADE_STATUS = 15000
export const POLL_REPORTS = 3000
export const POLL_SESSION = 3000
export const POLL_ACTION_LOG = 3000
export const POLL_USERS = 3000
export const POLL_ROLES = 3000
export const POLL_PROCESSES = 3000
export const POLL_DASHBOARD = 60000