import React, {PureComponent} from 'react'
import {createPortal} from 'react-dom'

export default class Portal extends PureComponent {

    componentWillMount() {
        this.node = document.createElement('div')
        document.body.appendChild(this.node)
    }

    componentWillUnmount() {
        document.body.removeChild(this.node)
    }

    render() {
        return createPortal(<div {...this.props}/>, this.node)
    }

}