import set from 'lodash-es/set'

import {post, get} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'
import mapper from 'utils/dataMapper'

const keysMap = {
    email: 'usr_email',
    password: 'usr_password',
}

const map = mapper(({user, permissions, settings}) => ({
    user: {
        id: parseInt(user.usr_id),
        email: user.usr_email,
        name: user.usr_name,
        perPage: user.usr_per_page,
        phone: user.usr_phone,
        status: user.usr_status,
        countryId: user.coy_id,
        countryName: user.coy_name,
    },
    permissions: Object
        .keys(permissions)
        .reduce((mapped, resource) => set(mapped, resource, permissions[resource]), {}),
    settings,
}))

export function login(email, password) {
    return post('login/sign', {
        usr_email: email,
        usr_password: password,
    }).then(map, e => mapValidationErrors(e, keysMap))
}

export function remindPassword(email) {
    return post('login/forgot', {usr_email: email})
        .catch(e => mapValidationErrors(e, keysMap))
}

export function logout() {
    return post('logout/out')
}

export function whoami(options) {
    return get('login/whoami', null, options).then(map)
}

export function setSettings(settings) {
    return post('settings/local', settings)
}