import moment, {isMoment} from 'moment'
import __, {__n} from 'utils/i18n'

import timezones from 'utils/timezones'

export function humanDate(datetime, format = 'LL') {
    if (!datetime) {
        return
    }

    const m = isMoment(datetime) ? datetime : timezones.server(datetime, 'YYYY-MM-DD HH:mm:ss')

    if (!m.isValid()) {
        return
    }

    let date = m.format(format)

    switch (true) {
        case m.isSame(timezones.server(), 'day'):
            return __('Today')

        case m.isSame(timezones.server().add(1, 'day'), 'day'):
            return __('Tomorrow')

        case m.isSame(timezones.server().subtract(1, 'day'), 'day'):
            return __('Yesterday')

        case m > timezones.server().startOf('day').subtract(5, 'day') && m < timezones.server().endOf('day').add(5, 'day'):
            // 5 days offset from today
            return m.format('dddd')

        case m.isSame(timezones.server(), 'year'):
            date = m.format(
                moment.localeData()
                    .longDateFormat(format)
                    .replace(/\/?\.?YYYY\.?\/?/, '')
                    .split(',')
                    .map(item => item.trim())
                    .filter(item => !!item)
                    .join(', '),
            )
            break
    }

    return date
}

export default function humanTime(datetime, withSeconds = false) {
    if (!datetime) {
        return
    }

    const m = isMoment(datetime) ? datetime : timezones.server(datetime, 'YYYY-MM-DD HH:mm:ss')

    if (!m.isValid()) {
        return
    }

    const date = humanDate(m, 'L')
    const time = m.format(withSeconds ? 'LTS' : 'LT')
    const result = new String(date + ', ' + time)

    result.date = date
    result.time = time

    return result
}

export function humanDiff(start, finish = null) {
    if (!finish) {
        finish = Date.now()
    }

    return humanDuration(moment.duration(finish - start))
}

export function humanDuration(duration, units = 'seconds') {
    const diff = moment.duration(duration, units)

    switch (true) {
        case !!diff.years():
            return __n('1 year', '%d years', diff.years())
        case !!diff.months():
            return __n('1 month', '%d months', diff.months())
        case !!diff.weeks():
            return __n('1 week', '%d weeks', diff.weeks())
        case !!diff.days():
            return __n('1 day', '%d days', diff.days())
        case !!diff.hours():
            return __n('1 hour', '%d hours', diff.hours())
        case !!diff.minutes():
            return __n('1 minute', '%d minutes', diff.minutes())
        case !!diff.seconds():
        default:
            return __('less than minute')
    }
}