import React, {Component, PureComponent} from 'react'
import PropTypes from 'prop-types'

import Check from 'ui/Check'
import Error from 'ui/Error'

import classes from 'classnames'
import IdSet from 'modules/selection/store'
import Tooltip from 'ui/Tooltip'
import {OverflowTextTooltip} from 'ui/Tooltip'
import {__, __n} from 'utils/i18n'
import {toId} from 'utils/toIds'

const preventBubble = e => e.stopPropagation()

const columnStyle = ({width, maxWidth}) => ({
    width: width || 100,
    maxWidth,
})

export class TableSelection extends Component {

    render() {
        const {className, selection, children} = this.props
        const count = selection && selection.count()
        const isFull = selection && selection.isFull()

        return (
            <div className={classes('table-selection', className, {
                'table-selection--active': count,
            })}>
                <div className="table-selection-bar-container">
                    <div className="table-selection-bar">
                        {__n('SELECTED 1 ROW', 'SELECTED %d ROWS', count)}
                        {isFull ? __(' (MAXIMUM)') : ''}
                    </div>
                </div>

                <div className="table-selection-content">
                    {children}
                </div>
            </div>
        )
    }

}

export class TableHeader extends PureComponent {
    renderCell = (column, key) => {
        const props = {
            key,
            className: classes('table-header-cell', {'table-header-cell--fixed': column.fixed}),
            style: columnStyle(column),
            children: column.name ? column.name() : null,
        }

        return <div {...props}/>
    }

    render() {
        const {columns} = this.props

        if (!columns || !columns[0].name) {
            return null
        }

        return (
            <div className="table-header">
                <div className="table-header-selectionStub"/>
                {columns.map(this.renderCell)}
            </div>
        )
    }
}

export class Row extends PureComponent {

    onChange = (e) => {
        const method = e.target.checked ? this.props.select : this.props.deselect

        if (method) {
            method(this.props.row)
        }
    }

    onClick = () => {
        this.props.onClick(this.props.row, this.props.index)
    }

    renderCellContent(render, CellComponent) {
        const {row} = this.props

        if (CellComponent) {
            return <CellComponent {...this.props}/>
        }

        if (render && render.prototype instanceof Component) {
            return React.createElement(render, this.props)
        }

        if (render instanceof Function) {
            return render(row, this.props)
        }

        if (render) {
            return row[render]
        }

        return null
    }

    renderCell = (column, key) => {
        const {fixed, align, render, component} = column
        const tooltip = column.hasOwnProperty('tooltip') ? column.tooltip : true

        const props = {
            key: column.key || key,
            className: classes('table-cell', {
                'table-cell--fixed': fixed,
                'table-cell--alignRight': align === 'right',
                'table-cell--alignCenter': align === 'center',
            }),
            style: columnStyle(column),
        }

        const content = this.renderCellContent(render, component)

        if (tooltip === true) {
            return (
                <div {...props}>
                    <OverflowTextTooltip className="table-cell-content">
                        {content}
                    </OverflowTextTooltip>
                </div>
            )
        }

        if (tooltip instanceof Function) {
            return (
                <div {...props}>
                    <Tooltip tooltip={tooltip(this.props.row)} className="table-cell-content">
                        {content}
                    </Tooltip>
                </div>
            )
        }

        return (
            <div {...props}>
                <div className="table-cell-content">
                    {content}
                </div>
            </div>
        )
    }

    render() {
        const {
            checked,
            hasSelection,
            onClick,
            columns,
            active,
            rowClassName,
            checkboxDisabled,
        } = this.props

        let className = classes('table-row', rowClassName, {
            'table-row--selected': checked,
            'table-row--active': active,
            'table-row--clickable': onClick,
        })

        return (
            <div onClick={onClick ? this.onClick : null}
                 className={className}>

                {hasSelection &&
                <Check className="table-cell table-cell--fixed"
                       key="select"
                       greyscale
                       checked={checked}
                       disabled={checkboxDisabled}
                       onClick={preventBubble}
                       onChange={this.onChange}/>
                }

                {columns.map(this.renderCell)}
            </div>
        )
    }
}

export default class Table extends Component {

    static propTypes = {
        onMount: PropTypes.func,
        onReceiveProps: PropTypes.func,
        onRowClick: PropTypes.func,
        className: PropTypes.any,
        select: PropTypes.func,
        deselect: PropTypes.func,
        columns: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            fixed: PropTypes.bool,
            width: PropTypes.number,
            maxWidth: PropTypes.number,
            minWidth: PropTypes.number,
            render: PropTypes.func,
        })),
        active: PropTypes.object,
        title: PropTypes.node,
        emptyMessage: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func,
        ]),
        rows: PropTypes.arrayOf(PropTypes.object),
        selection: PropTypes.instanceOf(IdSet),
        hasSelection: PropTypes.bool,
        fullHeight: PropTypes.bool,
        rowHeight: PropTypes.number,
    }

    static defaultProps: {
        selectionBar: boolean,
        hasSelection: boolean,
        rowHeight: number,
    } = {
        selectionBar: true,
        hasSelection: true,
        rowHeight: 45, // .table-row height form styles
    }

    state = {}

    height = 0

    element = null

    componentWillMount() {
        window.addEventListener('resize', this.updateHeight)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeight)
    }

    componentWillReceiveProps({rows}) {
        if (this.props.rows && rows && this.props.rows.length !== rows.length) {
            this.forceUpdate(this.updateHeight)
        }
    }

    updateHeight = () => {
        if (this.element) {
            this.height = this.element.offsetHeight
            this.handleScroll()
        }
    }

    handleRef = element => {
        this.element = element
        this.updateHeight()
    }

    renderRow = (row, index) => {
        const id = toId(row)

        if (!this.isRowVisible(index)) {
            return <div className="table-row" key={id ? id : '#' + index}/>
        }

        const {
            selection,
            hasSelection,
            select,
            deselect,
            columns,
            onRowClick,
            active,
            rowClassName,
            disabled,
            ...rest
        } = this.props

        delete rest.className
        delete rest.fullHeight
        delete rest.rowHeight
        delete rest.rows
        delete rest.row
        delete rest.selectionBar

        const isFull = selection && selection.isFull()
        const isSelectedRow = selection && !selection.has(row)

        return (
            <Row key={id ? id : '#' + index}
                 index={index}
                 row={row}
                 rowClass={rowClassName}
                 columns={columns}
                 onClick={onRowClick}
                 active={active ? active.id === row.id : false}
                 hasSelection={hasSelection && !!selection}
                 checked={hasSelection && selection && selection.has(row)}
                 checkboxDisabled={hasSelection && isFull && isSelectedRow}
                 select={select}
                 deselect={deselect}
                 disabled={disabled}
                 {...rest}
            />
        )
    }

    isRowVisible = (rowIndex) => {
        const {fromIndex, toIndex} = this.state
        const {fullHeight} = this.props

        // For non-optimized tables, rows allways visible.
        if (!fullHeight) {
            return true
        }

        return (fromIndex <= rowIndex) && (rowIndex <= toIndex)
    }

    handleScroll = () => {
        // Not optimize for tables without fullHeight property
        if (!this.props.fullHeight) {
            return
        }

        const {rowHeight} = this.props
        const {scrollTop} = this.element

        let fromIndex = Math.floor(scrollTop / rowHeight)
        const toIndex = Math.ceil((scrollTop + this.height + 1) / rowHeight)

        if ((this.state.fromIndex !== fromIndex) || (this.state.toIndex !== toIndex)) {
            this.setState({
                fromIndex,
                toIndex,
            })
        }
    }

    getEmptyMessage() {
        const {emptyMessage} = this.props

        if (!emptyMessage) {
            return __('No rows found')
        }

        if (emptyMessage instanceof Function) {
            return emptyMessage()
        }

        return emptyMessage
    }

    renderRows() {
        const {rows} = this.props

        if (!rows || rows.length === 0) {
            return <Error title={this.getEmptyMessage()}/>
        }

        return (
            <div className="table-content" ref={this.handleRef}>
                {rows.map(this.renderRow)}
            </div>
        )
    }

    renderContent() {
        const {selection, selectionBar} = this.props

        if (!selectionBar || !selection) {
            return this.renderRows()
        }

        return (
            <TableSelection selection={selection}>
                {this.renderRows()}
            </TableSelection>
        )
    }

    render() {
        const {columns, title, fullHeight, className, rows} = this.props

        const table = (
            <div onScroll={this.handleScroll} className={classes('table', className, {
                'table--fullHeight': fullHeight,
            })}>
                {rows && rows.length > 0 && <TableHeader columns={columns}/>}
                {title && rows && rows.length > 0 && <h3 className="table-title">{title}</h3>}
                {this.renderContent()}
            </div>
        )

        return table
    }
}