import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

export const LayoutColumn = ({className, small, half, scroll, ...props}) => (
    <div className={classes('layout-column', className, {
        'layout-column--small': small,
        'layout-column--half': half,
        'layout-column--scroll': scroll,
    })} {...props}/>
)

LayoutColumn.propTypes = {
    small: PropTypes.bool,
    half: PropTypes.bool,
    scroll: PropTypes.bool,
    className: PropTypes.string,
}

export const SideBar = ({className, ...props}) => (
    <div className={classes('sidebar', className)} {...props}/>
)

export const Pane = ({className, ...props}) => (
    <div className={classes('pane', className)} {...props}/>
)

export const ScrollView = ({className, ...props}) => (
    <div className={classes('scroll', className)} {...props}/>
)

export const Content = ({className, ...props}) => (
    <div className={classes('content', className)} {...props}/>
)

export const View = ({className, ...props}) => (
    <div className={classes('view', className)} {...props}/>
)

export default class Layout extends PureComponent {

    static propTypes = {
        vertical: PropTypes.bool,
        className: PropTypes.string,
    }

    render() {
        const {className, vertical, ...props} = this.props

        return (
            <div
                className={classes('layout', className, {
                    'layout--vertical': vertical,
                })}
                {...props}
            />
        )
    }

}