import {VENDOR_FIBRO_TRANSMITTER, VENDOR_NEO, VENDOR_POWER_MASTER} from 'constants/panelVendorType'

export const isNeoSerial = serial => serial.toString().length === 12

export function getVendor({serial, model}) {
    if (isNeoSerial(serial)) {
        return VENDOR_NEO
    }

    if (model == 'Fibro transmitter') {
        return VENDOR_FIBRO_TRANSMITTER
    }

    return VENDOR_POWER_MASTER
}
