import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Video from 'components/Video'
import IconRun from 'icons/run.svg'
import IconNotAvailable from 'icons/not-available.svg'

import classes from 'classnames'
import Spinner from 'ui/Spinner'

export default class CameraList extends PureComponent {

    static propTypes = {
        cameras: PropTypes.array,
        onStartRecord: PropTypes.func,
        isRequestAllowed: PropTypes.bool,
        isExportAllowed: PropTypes.bool,
    }

    state = {active: 0}

    showCamera(active) {
        this.setState({active})
    }

    startRecordVideo = () => {
        const {cameras, onStartRecord} = this.props
        const video = cameras && cameras[this.state.active]

        if (video && video.camera) {
            onStartRecord && onStartRecord(video.camera)
        }
    }

    renderCameraIcon(process, frames) {
        if (process && process.isRunning) {
            return <Spinner/>
        }

        if (!frames || frames.length === 0) {
            return <IconNotAvailable className="cameraList-item-image-icon cameraList-item-image-icon--notAvailable"/>
        }

        return <IconRun className="cameraList-item-image-icon"/>
    }

    renderCamera = ({preview, location, present, zone, process, frames}, index) => {
        return (
            <a key={'preview$' + zone}
               onClick={() => this.showCamera(index)}
               className={classes('cameraList-item', {
                   'cameraList-item--active': index === this.state.active,
               })}>
                <div className="cameraList-item-image">
                    <div className="cameraList-item-image-src" style={{backgroundImage: `url(${preview})`}}/>
                    {this.renderCameraIcon(process, frames)}
                </div>

                <strong className="cameraList-item-title">{location}</strong>
                <span className="cameraList-item-description">{zone}</span>
            </a>
        )
    }

    render() {
        const {onStartRecord, isRequestAllowed, isExportAllowed} = this.props
        const cameras = this.props.cameras || []
        const video = cameras[this.state.active]

        return (
            <div className="cameraList">
                {video &&
                <Video
                    className="card"
                    key={video.zone}
                    {...video}
                    title={video.location}
                    onStartRecord={onStartRecord ? this.startRecordVideo : null}
                    isRequestAllowed={isRequestAllowed}
                    isExportAllowed={isExportAllowed}
                />
                }

                {cameras && cameras.length > 1 &&
                <div className="cameraList-items">
                    {cameras.map(this.renderCamera)}
                    <div className="cameraList-item cameraList-item--stub"/>
                    <div className="cameraList-item cameraList-item--stub"/>
                    <div className="cameraList-item cameraList-item--stub"/>
                </div>
                }
            </div>
        )
    }

}