import Gettext from 'node-gettext'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import {sprintf} from 'sprintf-js'
import mapValues from 'lodash-es/mapValues'

import {warn} from './log'

const gettext = new Gettext({debug: false})

const loaded = []
const domain = 'messages'
let currentLocale = null

export const addLocale = (locale, data) => {
    const translations = mapValues(
        data.translations,
        context => mapValues(context, (msgstr) => ({msgstr})),
    )

    gettext.addTranslations(locale, domain, {
        ...data,
        translations,
    })

    loaded.push(locale)
}

export const isLoaded = (locale) => loaded.includes(locale)

export const setLocale = locale => {
    const momentLocale = [locale, locale.substr(0, 2)]
    moment.locale(momentLocale)
    momentTimezone.locale(momentLocale)

    gettext.setLocale(locale)
    currentLocale = locale
}

export function getCurrentLocale() {
    return currentLocale
}

const __ = (key, ...vars) => {
    if (!currentLocale) {
        warn(`Trying to get translation '${key}' while locale is not inited`)
    }

    if (vars.length) {
        return sprintf(gettext.gettext(key), ...vars)
    } else {
        return gettext.gettext(key)
    }
}

export default __
export {__}

export const __n = (key, pluralKey, count, vars) => {
    if (!vars) {
        vars = [count]
    }

    if (!currentLocale) {
        warn(`Trying to get translation '${key}' while locale is not inited`)
    }

    return sprintf(gettext.ngettext(key, pluralKey, count), ...vars)
}

export const months = () => new Array(12).fill(0).map(
    (_, month) => moment().month(month).format('MMMM')
)

export const weekdays = (format = 'ddd') => new Array(7).fill(0).map(
    (_, index) => {
        const day = moment().weekday(index)

        return {
            day: day.isoWeekday(),
            label: day.format(format),
        }
    }
)

export function ordinals(count = 10) {
    return new Array(count).fill(0).map((_, number) => {
        ++number
        switch (number) {
            case 1:
                return __('1st')
            case 2:
                return __('2nd')
            case 3:
                return __('3rd')
            default:
                return __(`${number}th`)
        }
    })
}