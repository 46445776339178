import React, {Component} from 'react'
import getHocName from 'utils/getHocName'

export default function withLifeCycle(lifeCycle: Object, mergeProps ?: (props: Object) => Object) {
    return WrappedComponent => class extends Component {
        static displayName = getHocName('WithLifeCycle', WrappedComponent)

        componentDidMount() {
            lifeCycle.onMount && lifeCycle.onMount(this.props)
            super.componentDidMount && super.componentDidMount()
        }

        componentWillUnmount() {
            lifeCycle.onUnmount && lifeCycle.onUnmount(this.props)
            super.componentWillUnmount && super.componentWillUnmount()
        }

        componentWillReceiveProps(props) {
            lifeCycle.onReceiveProps && lifeCycle.onReceiveProps(props, this.props)
            super.componentWillReceiveProps && super.componentWillReceiveProps(props)
        }

        componentWillUpdate(props) {
            lifeCycle.onUpdate && lifeCycle.onUpdate(props)
            super.componentWillUpdate && super.componentWillUpdate()
        }

        render() {
            const props = mergeProps
                ? mergeProps(this.props)
                : this.props

            return <WrappedComponent {...props}/>
        }
    }
}