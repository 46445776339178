import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import Hotkey from 'ui/Hotkeys'

export default class Modal extends PureComponent {

    static propTypes = {
        className: PropTypes.string,
        onClose: PropTypes.func,
        children: PropTypes.element,
    }

    handleRef = element => this.element = element

    handleOutsideClick = e => {
        // Cancel this handle, for better UX,
        // as result modals with editable form can't be closed by accidental click
        // TODO Refactor behaviour for close modal
        // if (e.target.contains(this.element)) {
        //     this.close(e)
        // }
    }

    close = e => this.props.onClose && this.props.onClose(e)

    render() {
        const {children, className} = this.props

        return (
            <div className={classes('modal', className)} onClick={this.handleOutsideClick}>
                <div className="blackout"/>
                <Hotkey shortcut="esc" action={this.close}/>

                <div className="modal-scroll">
                    <div className="modal-content" ref={this.handleRef}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}