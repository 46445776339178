import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import OuterClick from 'ui/OuterClick'
import SlideDown from 'ui/SlideDown'
import Portal from 'ui/Portal'

const PAGE_OFFSET = 12

const HORIZONTAL_ALIGN_MASK = 0b00011
const VERTICAL_ALIGN_MASK = 0b01100

export const ALIGN_LEFT = 0
export const ALIGN_RIGHT = 1
export const ALIGN_CENTER = 1 << 1
export const ALIGN_BOTTOM = 1 << 2
export const ALIGN_TOP = 1 << 3
export const ALIGN_WIDTH = 1 << 4

export const DropDownContent = ({className, title, ...props}) => {
    const content = <div className={classnames('dropDown-content', className)} {...props}/>

    if (!title) {
        return content
    }

    return (
        <Fragment>
            <div className="dropDown-title">
                {title}
            </div>

            {content}
        </Fragment>
    )
}

DropDownContent.propTypes = {
    title: PropTypes.node,
    className: PropTypes.string,
}

const isSameRect = (rectA, rectB) => {
    if (!rectA || !rectB) {
        return false
    }

    return ['left', 'top', 'right', 'bottom', 'x', 'y', 'width', 'height'].every(
        key => rectA[key] == rectB[key],
    )
}

const elementVisible = (rect) => rect.bottom >= 0 && rect.bottom <= window.innerHeight

export default class DropDown extends Component {
    static propTypes = {
        trigger: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        className: PropTypes.string,
        align: PropTypes.number,
        children: PropTypes.node,
        onShow: PropTypes.func,
        onHide: PropTypes.func,
    }

    static defaultProps = {
        align: ALIGN_LEFT | ALIGN_BOTTOM,
    }

    static childContextTypes = {
        hidePopup: PropTypes.func,
    }

    getChildContext() {
        return {hidePopup: this.hide}
    }

    state = {width: 0, height: 0}

    onOuterClick = (e) => {
        if (e.target === this.refs.element || this.refs.element.contains(e.target)) {
            return false
        }

        this.hide()
    }

    show = () => {
        const rect = this.refs.element.getBoundingClientRect()
        this.setState({active: true, show: true, rect})
        window.addEventListener('resize', this.setPosition)
        window.addEventListener('scroll', this.setPosition, {passive: true, capture: true})
        this.props.onShow && this.props.onShow()
    }

    hide = () => {
        this.setState({show: false})
        window.removeEventListener('resize', this.setPosition)
        window.removeEventListener('scroll', this.setPosition, {passive: true, capture: true})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setPosition)
        window.removeEventListener('scroll', this.setPosition, {passive: true, capture: true})
    }

    onHide = () => {
        this.props.onHide && this.props.onHide()
        this.setState({active: false})
    }

    setPosition = () => {
        const {width, height} = this.contentElement
            ? this.contentElement.getBoundingClientRect()
            : {}

        if (width !== this.state.width || height !== this.state.height) {
            this.setState({width, height})
        }

        const rect = this.refs.element ? this.refs.element.getBoundingClientRect() : null

        if (!isSameRect(this.state.rect, rect)) {
            this.setState({rect})
        }

        if (this.state.show && rect && !elementVisible(rect)) {
            this.hide()
        }
    }

    componentDidUpdate() {
        this.setPosition()
    }

    getPosition() {
        const {align} = this.props
        const {rect, width, height} = this.state
        const style = {}

        if (!width && !height) {
            return {left: 0, top: 0}
        }

        switch (align & HORIZONTAL_ALIGN_MASK) {
            case ALIGN_RIGHT:
                style.right = window.innerWidth - rect.right
                break
            case ALIGN_CENTER:
                style.left = rect.left
                break
            default:
            case ALIGN_LEFT:
                style.left = Math.min(rect.left, window.innerWidth - width - PAGE_OFFSET)
                break
        }

        switch (align & VERTICAL_ALIGN_MASK) {
            case ALIGN_TOP:
                style.top = Math.max(PAGE_OFFSET, rect.top - Math.max(0, rect.top + height - window.innerHeight + PAGE_OFFSET))
                break
            default:
            case ALIGN_BOTTOM:
                if (rect.top + rect.height > window.innerHeight - height - PAGE_OFFSET &&
                    rect.top - height > PAGE_OFFSET) {
                    style.bottom = window.innerHeight - rect.top
                } else {
                    style.top = Math.max(PAGE_OFFSET, rect.top + rect.height - Math.max(0, rect.top + rect.height + height - window.innerHeight + PAGE_OFFSET))
                }
                break
        }

        if (align & ALIGN_WIDTH) {
            style.minWidth = rect.width
        }

        return style
    }

    fixPosition = (element) => {
        this.contentElement = element
        this.setPosition()
    }

    handleClick = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if (this.state.show) {
            this.hide()
        } else {
            this.show()
        }
    }

    handlePortalClick(e) {
        e.stopPropagation()
    }

    renderDropDown() {
        const {active, show} = this.state

        if (!active) {
            return null
        }

        return (
            <Portal className="dropDown" onClick={this.handlePortalClick}>
                <OuterClick
                    className="dropDown-container"
                    style={this.getPosition()}
                    onOuterClick={show ? this.onOuterClick : null}
                >
                    <SlideDown
                        popdown
                        onRef={this.fixPosition}
                        onHide={this.onHide}
                        children={show && this.props.children}
                    />
                </OuterClick>
            </Portal>
        )
    }

    render() {
        const {trigger, className} = this.props

        return (
            <div className={classnames('dropDown-trigger', className)} ref="element" onClick={this.show}>
                {typeof trigger === 'function'
                    ? trigger({active: this.state.active})
                    : trigger}
                {this.renderDropDown()}
            </div>
        )
    }

}
