import React from 'react'
import PropTypes from 'prop-types'
import Ink from './Ink'

import classes from 'classnames'
import prepareInputName from 'utils/prepareInputName'

const mouseEvents = [
    'onClick',
    'onMouseDown',
    'onMouseUp',
    'onMouseMove',
]

const Check = (props) => {
    const {
        children,
        greyscale,
        className,
        indeterminate,
        noInk,
        checked,
        name,
        ...inputProps
    } = props

    const labelProps = {
        className: classes('check', className),
    }

    if (checked != undefined) {
        inputProps.checked = checked

        inputProps.ref = (el) => {
            if (el) {
                el.indeterminate = !checked && indeterminate
            }
        }
    }

    mouseEvents.forEach(eventName => {
        if (inputProps[eventName]) {
            labelProps[eventName] = inputProps[eventName]
            delete inputProps[eventName]
        }
    })

    return (
        <label {...labelProps}>
            <input type="checkbox"
                   className="check-input"
                   name={prepareInputName(name)}
                   {...inputProps}/>

            <span className={classes('check-box', {
                'check-box--greyscale': greyscale,
            })}>
                {inputProps.disabled || inputProps.readOnly || noInk ? null : <Ink primary/>}
            </span>

            {children && <span className="check-label">{children}</span>}
        </label>
    )
}

Check.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    greyscale: PropTypes.bool,
    indeterminate: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseMov: PropTypes.func,
}


export default Check