import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import classes from 'classnames'
import Portal from 'ui/Portal'

export default class Tooltip extends PureComponent {

    static propTypes = {
        className: PropTypes.any,
        tooltip: PropTypes.node,
    }

    state = {}

    handleRef = element => this.element = element

    onMouseEnter = () => {
        this.setState({show: !!this.props.tooltip})
    }

    onMouseLeave = () => {
        if (this.state.show) {
            this.setState({show: false, position: null})
        }
    }

    setPosition = (element) => {
        if (element) {
            const {left, top} = element.getBoundingClientRect()
            this.setState({position: {left, top}})
        }
    }

    render() {
        const {className, children, tooltip, ...props} = this.props

        props.className = classes('tooltip', className)

        return (
            <div
                {...props}
                ref={this.handleRef}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {children}
                {this.state.show &&
                <div className="tooltip-point" ref={this.setPosition}>
                    <Portal className="tooltip-container" style={this.state.position}>
                        <span className="tooltip-bubble">{tooltip || this.state.tooltip}</span>
                    </Portal>
                </div>
                }
            </div>
        )
    }
}

export class OverflowTextTooltip extends Tooltip {

    static propTypes = {
        className: PropTypes.any,
    }

    onMouseEnter = (e) => {
        if (!this.element || this.element.scrollWidth <= this.element.offsetWidth) {
            return
        }

        const tooltip = this.element.innerHTML.replace(/<\/?[^>]+(>|$)/g, ' ').trim()
        this.setState({tooltip, show: !!tooltip})
    }

}