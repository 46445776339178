import React from 'react'
import PropTypes from 'prop-types'

import Definition from 'ui/Definition'
import humanTime from 'utils/humanTime'
import momentPropType from 'utils/momentPropType'

const DateTime = ({datetime, timeFirst = false, withSeconds, Icon, className}) => {
    if (!datetime) {
        return <span className="empty">—</span>
    }

    const {date, time} = humanTime(
        datetime,
        withSeconds !== undefined ? withSeconds : timeFirst,
    )

    if (timeFirst) {
        return <Definition title={time} detail={date} Icon={Icon} className={className}/>
    }

    return <Definition title={date} detail={time} Icon={Icon} className={className}/>
}

DateTime.propTypes = {
    datetime: PropTypes.oneOfType([
        PropTypes.string,
        momentPropType,
        PropTypes.instanceOf(Date),
    ]),
    Icon: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.instanceOf(React.Component),
    ]),
    timeFirst: PropTypes.bool,
    withSeconds: PropTypes.bool,
    className: PropTypes.string,
}

export default DateTime