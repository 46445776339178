import React, {Component} from 'react'
import PropTypes from 'prop-types'

import classes from 'classnames'

export default class SlideDown extends Component {

    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        popdown: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.element,
        ]),
        defaultStateAnimated: PropTypes.bool,
        onRef: PropTypes.func,
        onHide: PropTypes.func,
    }

    static defaultProps = {
        defaultStateAnimated: true,
    }

    state = {height: 0, animated: true}

    constructor(props, context) {
        super(props, context)

        if (props.children) {
            this.state.child = props.children
        }

        if (!props.defaultStateAnimated) {
            this.state.height = undefined
            this.state.animated = false
        }
    }

    componentWillReceiveProps({children}) {
        if (this.state.child !== children) {
            if (children) {
                this.setState({child: children})
            } else if (this.element) {
                const height = this.element.getBoundingClientRect().height

                this.setState({height, animated: true}, () => {
                    if (this.element) {
                        // it's a kind of magic. Force browser reflow to run transition over height style
                        void this.element.offsetWidth
                    }

                    this.setState({height: 0})
                })
            }
        }
    }

    componentDidUpdate() {
        if (this.props.children && this.element && this.state.animated) {
            const height = this.element.getBoundingClientRect().height

            if (height !== this.state.height) {
                // it's a kind of magic. Force browser reflow to run transition over height style
                void this.element.offsetWidth

                // Setting new state forces re-render component and run transition
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({height})
            }
        }
    }

    onTransitionEnd = () => {
        const {children, onHide} = this.props

        if (!children) {
            this.setState({child: null, animated: true})
            onHide && onHide()
        } else if (this.state.animated) {
            this.setState({animated: false})
        }
    }

    onRef = (element) => {
        this.props.onRef && this.props.onRef(element)
        this.element = element

        if (!element) {
            return
        }

        const rect = element.getBoundingClientRect()
        const height = rect.height

        this.setState({height, animated: true})
    }

    render() {
        const {className, popdown, children, style} = this.props
        const {height, child, animated} = this.state

        const props = {
            className: classes('slideDown', className, {
                'slideDown--active': children && height > 0,
                'slideDown--popdown': popdown,
            }),
            onTransitionEnd: this.onTransitionEnd,
            style,
        }

        if (animated) {
            props.style = {
                ...style,
                height,
            }
        }

        return (
            <div {...props}>
                {child && <div className="slideDown-container" ref={this.onRef}>{child}</div>}
            </div>
        )
    }
}