import {delay} from 'redux-saga'
import {cancel, fork, take, call} from 'redux-saga/effects'

export default function* createPollerSaga(startAction, stopAction, interval, iteratee, startBeforeDelay = false) {
    while (true) {
        const action = yield take(startAction)
        if (startBeforeDelay) {
            yield call(iteratee, action)
        }
        const pollTask = yield fork(poller, interval, iteratee, action)
        yield take(stopAction)
        yield cancel(pollTask)
    }
}

export function* poller(interval, iteratee, ...args) {
    while (true) {
        if (Number.isInteger(interval)) {
            yield delay(interval)
        } else {
            yield call(interval, ...args)
        }

        yield call(iteratee, ...args)
    }
}