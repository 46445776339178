import React, {PureComponent} from 'react'

import classes from 'classnames'

export default class Ink extends PureComponent {

    state = {}

    constructor(props, context) {
        super(props, context)
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseUp = this.onMouseUp.bind(this)
        this.onTransitionEnd = this.onTransitionEnd.bind(this)
        this.ref = this.ref.bind(this)
    }

    ref(element) {
        this.element = element
    }

    onMouseDown(e) {
        const rect = this.element.getBoundingClientRect()

        this.setState({
            animating: false,
            down: true,
            active: true,
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
            w: rect.width,
            h: rect.height,
        })

        window.addEventListener('mouseup', this.onMouseUp, true)
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onMouseUp, true)
    }

    onMouseUp() {
        window.removeEventListener('mouseup', this.onMouseUp, true)
        this.setState({down: false, animating: true})
    }

    onTransitionEnd() {
        this.setState(({down}) => ({animating: down, active: down}))
    }

    renderInkFill() {
        const {down, animating, x, y, w, h} = this.state
        const size = 2 * Math.sqrt(w * w + h * h)

        const style = {
            opacity: down ? .1 : 0,
            width: size,
            height: size,
            transform: `translate(-50%, -50%) translate(${x}px, ${y}px)` + (animating ? '' : ' scale(0.0001, 0.0001)'),
        }

        let className = 'ink-fill'

        if (animating) {
            className += ' ink-fill--animating'
        } else {
            setTimeout(() => {
                this.setState({animating: true})
            }, 0)
        }

        return <i className={className} style={style} onTransitionEnd={this.onTransitionEnd}/>
    }

    render() {
        const {primary, greyscale, className, children} = this.props

        const props = {
            ref: this.ref,
            onMouseDown: this.onMouseDown,
            className: classes('ink', className, {
                'ink--primary': primary,
                'ink--greyscale': greyscale,
            }),
        }

        if (this.state.active) {
            return <span {...props}>{this.renderInkFill()}{children}</span>
        }

        return <span {...props}>{children}</span>
    }
}