import React, {Component, PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

export default class Definition extends PureComponent {

    static propTypes = {
        className: PropTypes.string,
        iconClassName: PropTypes.string,
        Icon: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.instanceOf(Component),
        ]),
        title: PropTypes.node,
        detail: PropTypes.node,
        multiLine: PropTypes.bool,
    }

    render() {
        const {title, className, iconClassName, multiLine, detail, Icon} = this.props

        return (
            <span className={classes('definition', className, {
                'definition--multiLine': multiLine,
            })}>
                {Icon && <Icon className={classes('definition-icon', iconClassName)}/>}
                    <strong className="definition-title">{title}</strong>
                <span className="definition-detail">{detail}</span>
            </span>
        )
    }
}