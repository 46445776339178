import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import NavTabs, {NavTab} from 'ui/NavTabs'

const TabsDefaultLayout = ({stretch, nav, tab}) => (
    <div className="tabs-holder">
        {nav}
        {tab}
    </div>
)

export default class Tabs extends PureComponent {

    static propTypes = {
        Layout: PropTypes.any,
    }

    static defaultProps = {
        Layout: TabsDefaultLayout,
    }

    state = {index: 0}

    componentWillMount() {
        const index = React.Children.toArray(this.props.children)
            .findIndex(child => child.props.isDefaultTab)

        if (index !== -1) {
            this.setState({index})
        }
    }

    componentWillReceiveProps({children}) {
        if (this.props.children != children) {
            const active = React.Children.toArray(this.props.children)[this.state.index]

            const index = React.Children.toArray(children)
                .findIndex(child => child.key == active.key)

            this.setState({index: Math.max(0, index)})
        }
    }

    renderNav() {
        const tabs = React.Children.toArray(this.props.children)
            .map((child, index) => (
                <NavTab
                    key={index}
                    active={index == this.state.index}
                    onClick={() => this.setState({index})}>
                    {child.props.name}
                </NavTab>
            ))

        return <NavTabs stretch>{tabs}</NavTabs>
    }

    render() {
        const {Layout, ...props} = this.props
        const tab = React.Children.toArray(this.props.children)[this.state.index]
        const nav = this.renderNav()

        return <Layout {...props} {...{tab, nav}}/>
    }
}

export const Tab = ({children}) => children

Tab.propTypes = {
    name: PropTypes.node.isRequired,
}