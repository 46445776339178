import React, {PureComponent} from 'react'
import classes from 'classnames'
import PropTypes from 'prop-types'
import {Link, Route} from 'react-router-dom'

import DropDownIcon from 'icons/drop-down.svg'
import DropDown from 'ui/DropDown'

export const NavTab = ({className, active, ...props}) => (
    <div className={classes('tab', className, {
        'tab--active': active,
    })} {...props}/>
)

NavTab.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.any,
}

export const NavTabLink = ({to, exact, className, ...props}) => (
    <Route path={to} exact={exact}>
        { ({match}) =>
            <Link to={to} className={className}>
                <NavTab active={!!match} {...props}/>
            </Link>
        }
    </Route>
)

NavTabLink.propTypes = {
    to: PropTypes.string,
    exact: PropTypes.bool,
    className: PropTypes.any,
}

export class NavTabPopup extends PureComponent {

    static propTypes = {
        label: PropTypes.node,
    }

    state = {}

    onClick = () => {
        this.setState(({active}) => ({active: !active}))
    }

    render() {
        const trigger = (
            <NavTab>
                {this.props.label}
                <DropDownIcon className="tab-icon"/>
            </NavTab>
        )

        return (
            <DropDown trigger={trigger}>
                {this.props.children}
            </DropDown>
        )
    }

}

const NavTabs = ({className, onRef, stretch, ...props}) => (
    <nav ref={onRef} className={classes('tabs', {'tabs--stretch': stretch}, className)} {...props}/>
)

NavTabs.propTypes = {
    className: PropTypes.any,
    stretch: PropTypes.bool,
}

export default NavTabs