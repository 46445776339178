import React, {Fragment} from 'react'
import Tabs, {Tab} from 'ui/Tabs'
import CameraList from 'components/CameraList'
import {__} from 'utils/i18n'
import {ScrollView} from 'ui/Layout'
import {withPermission} from 'containers/withPermission'
import {exports, request} from 'permissions/panel/devices/video/actions'
import {compose} from 'redux'

const Layout = ({nav, tab}) => {
    return (
        <Fragment>
            <div className="sidebar-header">
                {nav}
            </div>

            <ScrollView className="sidebar-content">
                {tab}
            </ScrollView>
        </Fragment>
    )
}

export function VideoTabs({video, startRecordVideo, hasLive, children, isRequestAllowed, isExportAllowed}) {
    if (!hasLive) {
        return (
            <ScrollView className="sidebar-content">
                <CameraList cameras={video.preloaded}/>
                {children}
            </ScrollView>
        )
    }

    return (
        <Tabs Layout={Layout}>
            <Tab name={__('EVENT VIDEO')}>
                <CameraList cameras={video.preloaded} isExportAllowed={isExportAllowed}/>
                {children}
            </Tab>
            <Tab name={__('LIVE VIDEO')}>
                <CameraList cameras={video.live} onStartRecord={startRecordVideo} isRequestAllowed={isRequestAllowed} isExportAllowed={isExportAllowed}/>
                {children}
            </Tab>
        </Tabs>
    )
}

export default compose(
    withPermission({
        isRequestAllowed: request,
        isExportAllowed: exports,
    }),
)(VideoTabs)