import React, {PureComponent} from 'react'
import classes from 'classnames'

import Modal from 'ui/Modal'

import IconPlay from 'icons/run.svg'
import IconPause from 'icons/pause.svg'
import Portal from 'ui/Portal'
import Spinner from 'ui/Spinner'

export default class VideoModal extends PureComponent {
    state = {}

    onPlaying = () => {
        this.unmounted || this.setState({playing: true})
    }

    onPause = () => {
        this.unmounted || this.setState({playing: false})
    }

    onReady = () => {
        this.unmounted || this.setState({ready: true})
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    togglePlay = () => {
        const {video} = this.refs

        if (video) {
            video.paused ? video.play() : video.pause()
        }
    }

    renderContent() {
        const {video, onClose} = this.props
        const {ready, playing} = this.state
        // as not all platfroms support h.264 in mp4 we want to provide webm source first
        const sortWeight = (type) => type === 'webm' ? -1 : 0

        const sources = video &&
            Object.keys(video).sort((a, b) => sortWeight(a) - sortWeight(b))
                .map(type => <source key={type} src={video[type]} type={'video/' + type}/>)

        return (
            <div className="modal-video">
                {ready && <a className="modal-close modal-close--circle" onClick={onClose}>×</a>}

                {ready && playing &&
                <div className="modal-video-icon-circle">
                    <IconPlay className="modal-video-icon modal-video-icon--play"/>
                </div>
                }
                {ready && !playing &&
                <div className="modal-video-icon-circle">
                    <IconPause className="modal-video-icon"/>
                </div>
                }

                {!ready && <Spinner/>}

                <video className={classes('modal-video-src', {'modal-video-src--hidden': !ready})}
                       autoPlay
                       loop
                       ref="video"
                       onClick={this.togglePlay}
                       onPause={this.onPause}
                       onLoadedData={this.onReady}
                       onPlaying={this.onPlaying}>
                    {sources}
                </video>
            </div>
        )
    }

    render() {
        const {onClose} = this.props

        return (
            <Portal>
                <Modal onClose={onClose} className="modal--video">
                    {this.renderContent()}
                </Modal>
            </Portal>
        )
    }
}