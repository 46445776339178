import {Component} from 'react'
import PropTypes from 'prop-types'
import key from 'keymaster'

// pass all events, implement custom scopes filtering
key.filter = e => true

// keymaster doesn't support remove specific listener (https://github.com/madrobby/keymaster/pull/133)
// so use proxy class to store those listeners
const listeners = new class Listeners {

    binds = {}

    inputScopeRegex = /^(INPUT|TEXTAREA|SELECT)$/

    handle = (event, handler) => {
        const {shortcut} = handler

        if (!this.binds.hasOwnProperty(shortcut) || event.cancelBubble) {
            return
        }

        const tagName = (event.target || event.srcElement).tagName
        const scope = this.inputScopeRegex.test(tagName) ? 'input' : 'default'

        this.binds[shortcut].find(
            (item) => {
                if (scope === item.scope || item.scope === 'all') {
                    item.listener(event, handler)
                }

                return event.cancelBubble
            },
        )

        event.stopPropagation()
    }

    add(shortcut, scope, listener) {
        if (!this.binds.hasOwnProperty(shortcut)) {
            this.binds[shortcut] = []
            key(shortcut, this.handle)
        }

        this.binds[shortcut].unshift({
            scope,
            listener,
        })
    }

    remove(shortcut, scope, listener) {
        if (!this.binds.hasOwnProperty(shortcut)) {
            return
        }

        this.binds[shortcut] = this.binds[shortcut].filter(item => item.listener !== listener)

        if (this.binds[shortcut].length === 0) {
            delete this.binds[shortcut]
            key.unbind(shortcut)
        }
    }
}

export default class Hotkey extends Component {

    static propTypes = {
        shortcut: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        scope: PropTypes.oneOf(['all', 'default', 'input']),
    }

    static defaultProps = {
        scope: 'default',
    }

    handle = (...args) => this.props.action && this.props.action(...args)

    componentDidMount() {
        listeners.add(this.props.shortcut, this.props.scope, this.handle)
    }

    componentWillUnmount() {
        listeners.remove(this.props.shortcut, this.props.scope, this.handle)
    }

    shouldComponentUpdate() {
        return false
    }

    render() {
        return null
    }

}